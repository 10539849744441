<div class="d-flex flex-column">
  <button *ngIf="showPrimaryButton"
          class="btn btn-primary-button mt-4 mb-2 text-uppercase font-weight-bold"
          [type]="primaryButtonType"
          (click)="handlePrimaryButtonClick()">
    {{ primaryButtonLabel | translate: { customerName: customerName } }}
  </button>

  <button
    *ngIf="showCancelButton"
    class="btn btn-outline-black mt-4 mb-2 text-uppercase font-weight-bold"
    type="button"
    (click)="cancelButtonClick()"
  >
    {{ 'common_cancel' | translate }}
  </button>
</div>
