import { InjectionToken } from '@angular/core';

export const adminRouteNames = {
  Entry: '',
  Base: '',
  UsersManagement: {
    Entry: 'users-management',
  },
  Development: {
    Entry: 'development',
  },
  Email: {
    Entry: 'email',
    Settings: 'configurations',
    Templates: {
      Entry: 'templates',
      Manage: 'manage-templates',
    },
    Providers: 'providers',
  },
  Swagger: {
    Entry: 'swagger',
    Authentication: 'auth',
    Configuration: 'config-backend',
    Medical: 'backend',
    Middleware: 'middleware-backend',
    Calendar: 'calendaring',
    Proprietary: 'proprietary',
    Wearable: 'wearable',
    Chatbot: 'chatbot-api',
    Amp: 'ai-model-proxy',
  },
  Organisations: {
    Entry: 'organisations',
    Practitioners: 'practitioners',
  },
  Reports: {
    Entry: 'reports',
    MedMij: 'medmij',
  },
  Translations: {
    Entry: 'translations',
  },
};

export type AdminRouteNames = typeof adminRouteNames;

export const ADMIN_ROUTE_NAMES = new InjectionToken<AdminRouteNames>('Route Names connected by Admin` Shell library');

export const provideAdminRouteNames = (entry = '') => [
  {
    provide: ADMIN_ROUTE_NAMES,
    useValue: { ...adminRouteNames, Entry: entry },
  },
];
