import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'medrecord-auth-twofa-action-buttons',
  templateUrl: './action-buttons.component.html'
})
export class ActionButtonsComponent {
  @Input() primaryButtonLabel: string;
  @Input() primaryButtonType = 'button';
  @Input() showPrimaryButton = true;
  @Input() showCancelButton = true;
  @Input() customerName = 'Medsafe';

  @Output() primaryButton: EventEmitter<void> = new EventEmitter();
  @Output() cancelButton: EventEmitter<void> = new EventEmitter();

  handlePrimaryButtonClick(): void {
    this.primaryButton.emit();
  }

  cancelButtonClick(): void {
    this.cancelButton.emit();
  }
}
